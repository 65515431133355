import {
  AdminGetUser,
  AdminUpdateBalance,
  CreatePoll,
  CreatePost,
  GetPolls,
  GetUser,
  Vote,
} from "../../types/api";
import { functions } from "./configs";

// const useEmulator = () => {

// }
// export const createPoll = functions.httpsCallable("createPoll") as CreatePoll;

// export const getPolls = functions.httpsCallable("getPolls") as GetPolls;

// export const addVote = functions.httpsCallable("vote") as Vote;

export const createPoll =
  typeof window !== `undefined`
    ? (functions.httpsCallable("createPoll") as CreatePoll)
    : null;

export const getPolls =
  typeof window !== `undefined`
    ? (functions.httpsCallable("getPolls") as GetPolls)
    : null;

export const addVote =
  typeof window !== `undefined`
    ? (functions.httpsCallable("vote") as Vote)
    : null;

export const updateBalance =
  typeof window !== `undefined`
    ? (functions.httpsCallable("updateBalance") as AdminUpdateBalance)
    : null;

export const getUser =
  typeof window !== `undefined`
    ? (functions.httpsCallable("getUser") as GetUser)
    : null;

export const createPost =
  typeof window !== `undefined`
    ? (functions.httpsCallable("createPost") as CreatePost)
    : null;
export const getUsers =
  typeof window !== `undefined`
    ? (functions.httpsCallable("getUsers") as AdminGetUser)
    : null;
