import React, { useState } from "react";

import { Category, ID, NewPost, Tier, TimeLimit, Title } from "../types/api";
import axios from "../utils/axios";
import { addVote, createPost, getUser } from "../utils/firebase/functions";

interface NewOption {
  title: string;
  description?: string;
  budget: number;
}
interface NewPoll {
  creatorId: ID;
  title: Title;
  description: string;
  options: NewOption[];
  categories: Category[];
  timeLimit: TimeLimit;
}
const newPoll: NewPoll = {
  title: "hello",
  creatorId: "bkg4C9nw0btSEnWz7teY8ue10jbg",
  description: "description",
  options: [
    {
      title: "option 1",
      description: "hello",
      budget: 10,
    },
    {
      title: "option 2",
      description: "hello",
      budget: 5,
    },
    {
      title: "option 3",
      description: "hello",
      budget: 100,
    },
  ],
  categories: ["travel"],
  timeLimit: new Date().toString(),
};

const NotFoundPage = () => {
  const getCurrentUser = async () => {
    try {
      const {
        data: { user },
      } = await getUser!({ userId: "bkg4C9nw0btSEnWz7teY8ue10jbg" });
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const createPollAPI = async () => {
    try {
      const res = await axios.post("/polls");
    } catch (err) {
      console.log(`error: ${err}, details: ${err.message}`);
    }
  };

  const editPoll = async () => {
    try {
      return await axios.patch("/polls/LdV8NYazq7rFq1qnGrvF", {
        title: "new title",
      });
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const getOnePoll = async () => {
    try {
      const res = await axios.get("/polls/ffPlES3FkfRPrwhjfh7u");
      console.log({ res });
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const getPollAPI = async () => {
    try {
      const res = await axios.get("/polls");
      console.log({ res });
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const voteAPI = async () => {
    try {
      const { data } = await addVote!({
        optionId: "Ie6yoxgUYLiLl3XFFwB6",
        pollId: "CePaxKmk8BIqVsRrTtIU",
        tier: Tier.THREE,
      });
      console.log(data.message);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const addOption = async () => {
    try {
      axios.post("/polls/LdV8NYazq7rFq1qnGrvF/options/new");
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const editOption = async () => {
    try {
      axios.patch(
        "/polls/LdV8NYazq7rFq1qnGrvF/options/c9a87595-9ac9-4226-897a-90e3e0122491"
      );
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const adminGetUserAPI = async () => {
    try {
      const res = await axios.get(
        "admin/users?fields=displayName&op===&values=andrew"
      );
      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const adminGetOneUser = async () => {
    try {
      const res = await axios.get("admin/users/68wVRR7LBOlYbaLoypB7nrebMqHO");
      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const updateBalanceAPI = async () => {
    try {
      axios.patch("admin/users/68wVRR7LBOlYbaLoypB7nrebMqHO/balance", {
        balance: 50,
      });
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const newPost: NewPost = {
    description: "weewe",
    url: "url",
  };

  const adminGetOnePoll = async () => {
    try {
      const res = await axios.get("admin/polls/LdV8NYazq7rFq1qnGrvF");
      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const adminGetAllPolls = async () => {
    try {
      const res = await axios.get("admin/polls");
      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const adminUpdatePoll = async () => {
    try {
      const res = await axios.patch("admin/polls/LdV8NYazq7rFq1qnGrvF");
      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const createPostAPI = async () => {
    try {
      const { data } = await createPost!({
        post: newPost,
        pollId: "CePaxKmk8BIqVsRrTtIU",
      });

      console.log(data.postId);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const adminReadAllTransaction = async () => {
    try {
      const res = await axios.get("admin/transactions");
      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const adminReadOneTransaction = async () => {
    try {
      const res = await axios.get("admin/transactions/6hKCdOBljfZIVZMpdEPS");
      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const newDare = async () => {
    try {
      const res = await axios.post(
        "/polls/p6GNLLMPWOJEFNyg56Np/options/dare/new",
        {
          title: "hello",
          balance: 15,
        }
      );
      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };

  const [file, setFile] = useState(null);
  const uploadTeaser = async () => {
    try {
      const formData = new FormData();

      formData.append("teaser", file);

      const res = await axios.post(
        "/teasers/new",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(res);
    } catch (err) {
      console.log(`error: ${err}, details: ${err.details}`);
    }
  };
  return (
    <>
      {process.env.NODE_ENV == "development" ? (
        <>
          <h1>Andrew's Playground</h1>{" "}
          <button onClick={getCurrentUser}>getUser</button>
          <button onClick={createPollAPI}>create poll</button>
          <button onClick={getPollAPI}>get poll</button>
          <button onClick={voteAPI}>vote</button>
          <button onClick={adminGetUserAPI}>GetUsers</button>
          <button onClick={updateBalanceAPI}>UpdateBalance</button>
          <button onClick={createPostAPI}>Create Post</button>
          <button onClick={editPoll}>Edit Poll</button>
          <button onClick={getOnePoll}>Get One Poll</button>
          <button onClick={addOption}>Add Option</button>
          <button onClick={editOption}>Edit Option</button>
          <button onClick={adminGetOneUser}>Admin get one user</button>
          <button onClick={adminGetOnePoll}>Admin get one poll</button>
          <button onClick={adminGetAllPolls}>Admin get all polls</button>
          <button onClick={adminUpdatePoll}>Admin update poll</button>
          <button onClick={adminReadOneTransaction}>
            Admin get one transaction
          </button>
          <button onClick={adminReadAllTransaction}>
            Admin get all transactions
          </button>
          <button onClick={newDare}>New Dare</button>
          <button onClick={uploadTeaser}>Upload Teaser</button>
          <input
            type="file"
            name="teaser"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default NotFoundPage;
